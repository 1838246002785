<template>
    <span v-if="status">Aktivan</span>
    <span v-else>Neaktivan</span>
</template>

<script>

    import UtilService from '@/service/UtilService.js';

    export default {
        name: 'employee-status-template',

        data: function() {
            return {
                data: null,
            }
        },
        


        computed: {
            status () {
                if (this.data.work_end_date){
                    let checkout = UtilService.convertMoment(this.data.work_end_date)
                    if (checkout.diff(UtilService.getMoment(), 'days') < 0){
                        return false
                    }
                }
                
                if (this.data.user.deleted) return false

                return true;
            },
        }

    }
</script>

<style>
  #tooltipContent {
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
</style>
