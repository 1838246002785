<template>
    <div class="workers-page">
        <Navbar />
        <Breadcrumbs></Breadcrumbs>
        <Employees/>
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Employees from '../../components/employees/Employees.vue'
import Footer from '@/components/Footer.vue';

export default {
    name: 'Home',
    components: {
        Navbar,
        Employees,
        Footer
    }
}
</script>
